import React, { useContext, useState } from "react"
import { useForm } from "react-hook-form"
import axios from "axios"
import * as yup from "yup"
import { getIskibrisUrl } from "../lib/api"
import useGtm from "../hooks/useGtm"
import { useRouter } from "next/router"
import Cookies from "js-cookie"

import Button from "@mui/material/Button"
import Divider from "@mui/material/Divider"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"
import CircularProgress from "@mui/material/CircularProgress"

import AppStateContext from "../context/AppStateContext"
import DispatchContext from "../context/DispatchContext"
import iskibris from "../api/iskibris/client"

export default function BusinessAccountForm(props) {
  
  const { tagEvent } = useGtm()
  const router = useRouter()
  const [loading, setLoading] = useState(false)
  // const [loading, setIsAuthError] = useState(false)
  const { AuthState } = useContext(AppStateContext)
  const { AuthDispatcher } = useContext(DispatchContext)

  const [serverErrors, setServerErrors] = useState({})
  const SignupSchema = yup.object().shape({
    name: yup.string().optional(),
    phone_number: yup
      .string()
      .matches(/\+?\d+$/)
      .optional(),
    company_name: yup.string().required(),
    email: yup
      .string()
      .matches(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/),
    option: yup.number().required().nullable()
  })

  const { register, handleSubmit, errors, setError, setValue, clearErrors, watch } = useForm({
    validationSchema: SignupSchema,
    defaultValues: {
      option: 4
    }
  })

  // React.useEffect(() => {
  //   if (AuthState.isLoggedIn && !AuthState.user) {
  //     iskibris
  //       .get("/api/user/profile")
  //       .then(response => {
  //         const user = response.data
  //         setValue("name", user.full_name)
  //         setValue("phone_number", user.phone_number)
  //         setValue("email", user.email)
  //         AuthDispatcher({ type: "login" })
  //         AuthDispatcher({ type: "addUser", payload: user })
  //       })
  //       .catch(error => {})
  //   }
  // }, [AuthState.isLoggedIn, AuthDispatcher, AuthState.user, setValue])

  const url = getIskibrisUrl("/api/employers/apply")


  const cookieAuth = Cookies.get("auth_token")

  const onSubmit = async (data, e) => {
    e?.preventDefault()
    setServerErrors({})
    setLoading(true)
    const application = {
      name: data.name,
      phone_number: data.phone_number.toString().replaceAll(" ", ""),
      company_name: data.company_name,
      email: data.email,
      option: data.option,
    }

    await axios
      .post(url, application, {
        headers: {
          "Authorization": `Bearer ${cookieAuth}`,
          "Accept": 'application/json'
        }
      })
      .then(res => {
        if (application.option === "4") {
          tagEvent({ event: "generate_lead", data: { company_name: application.company_name } })
          // Cookies.set("auth_token", res.data.auth_token, {
          //   domain: process.env.NEXT_PUBLIC_APP_DOMAIN ?? ".iskibris.com",
          //   sameSite: "None",
          //   secure: true,
          //   expires: 364
          // })
          Cookies.set("is_employer", "true", {
            domain: process.env.NEXT_PUBLIC_APP_DOMAIN ?? ".iskibris.com",
            sameSite: "None",
            secure: true,
            expires: 750
          })
          // AuthDispatcher({ type: "login" })
        }

        setLoading(false)
        // AuthDispatcher({ type: "login" })

        if(props.onSuccess){
          props.onSuccess(application)
        }

        if(!props.withoutRedirectToSuccess){
          router.push("/success")
        }
      })
      .catch(error => {
        tagEvent({ event: "company_activation_failed", data: { user_name: application.name, company_name: application.company_name, error_code: error?.response?.status } })
        if (!error?.response) {
          setServerErrors({
            ...serverErrors,
            application_error: "Bir hata oluştu, internet bağlantınızın olduğunu teyit edip tekrar deneyiniz. aynı hata oluşmaya devam ediyorsa lütfen destek takımımıza ulaşınız."
          })
        }
        if (error?.response?.status === 500) {
          setServerErrors({ ...serverErrors, server_error: "Bilinmeyen bir hata oluştu, aynı hata oluşmaya devam ediyorsa lütfen destek takımımıza ulaşınız." })
        }
        if (error?.response?.status === 401) {
          setServerErrors({ ...serverErrors, authentication_error: "Kimlik bilgileriniz kayıtlarımızla eşleşmedi." })
        }
        if(error.response.data.errors){
          Object.keys(error.response.data.errors).forEach(key => {
            if (key === "message") {
              setServerErrors({ ...serverErrors, generic_error: error.response.data.errors[key] })
            } else {
              setError(key, {
                type: "server",
                message: error.response.data.errors[key]
              })
            }
          })
        }

        setLoading(false)
      })
  }

  return (
    <div className="form" id="application-form">
      <div className="form__title">İşletme Hesabı Oluştur</div>
      <Typography style={{textAlign: "center"}}>Formu doldurun İş Kıbrıs İşveren Hesabınızı oluşturalım</Typography>
      {/* <div className="form__subtitle">Formu doldurun, İş Kıbrıs Hesabınızı oluşturalım</div> */}
      {serverErrors.generic_error && (
        <div className="form__error--panel" key="error-panel-generic">
          <span>{serverErrors.generic_error}</span>
        </div>
      )}
      {serverErrors.application_error && (
        <div className="form__error--panel" key="error-panel-application_error">
          <span>{serverErrors.application_error}</span>
        </div>
      )}
      {serverErrors.server_error && (
        <div className="form__error--panel" key="error-panel-server_error">
          <span>{serverErrors.server_error}</span>
        </div>
      )}
      {serverErrors.authentication_error && (
        <div className="form__error--panel" key="error-panel-authentication_error">
          <span>{serverErrors.authentication_error}</span>
        </div>
      )}
      <form className="form__inner" onSubmit={handleSubmit(onSubmit)}>
        <div className="form__group">
          <TextField
            autoFocus
            type="text"
            id="company_name"
            name="company_name"
            label="Firma Adı"
            size="small"
            variant="outlined"
            inputRef={register({ required: { value: true, message: "Bu alan gereklidir." } })}
          />
        </div>
        {errors.company_name && <span className="form__error" key="eer-com-nam">{errors.company_name.message}</span>}
        
        <div className="form__group">
          <TextField
            id="email"
            label="E-posta"
            type="text"
            name="email"
            variant="outlined"
            size="small"
            inputRef={register({
              required: { value: true, message: "Bu alan gereklidir." },
              pattern: {
                value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                message: "Lütfen doğru bir email giriniz"
              }
            })}
          />
        </div>
        {errors.email && <span className="form__error" key="message-error">{errors.email.message}</span>}

        <div className="form__group">
          <TextField
            id="phone_number"
            size="small"
            variant="outlined"
            label="Telefon numarası"
            type="tel"
            name="phone_number"
            inputRef={register({ required: { value: true, message: "Bu alan gereklidir." }, pattern: { value: /\+?\d+$/, message: "Lütfen doğru bir telefon numarası giriniz." } })}
          />
        </div>
        {errors.phone_number && <span className="form__error" key="form-error-id">{errors.phone_number.message}</span>}
        
        <div className="form__group" style={{ display: "none" }}>
          <select name="option" className="form__control" ref={register}>
            <option value="">---</option>
            <option value="4" selected>
              Benim için deneme oluştur
            </option>
          </select>
        </div>
        <div className="form__group" key={Math.random()}>
          <Button type="submit" style={{ background: "#e61e8f", color: "white" }} key={Math.random()}>
            {loading ? <CircularProgress size="30px" style={{ color: "white" }} key={Math.random()} /> : "Gönder"}
          </Button>
        </div>
      </form>
    </div>
  )
}
